/**********************************************************************************************/
/******************************************** contact *****************************************/
/**********************************************************************************************/

.contact__icon-phone1 {
    margin-bottom: -6.4vw;
    font-size: 9.5vw;
    color: rgb(112, 190, 247);
}

.contact__picture {
    position: relative;
    left: -152vw;
    width: 500vw;
    height: auto;
    margin-bottom: -2vw;
    filter: brightness(110%);
    z-index: 1;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /******************************************** contact *****************************************/
    /**********************************************************************************************/

    .contact__icon-phone1 {
        margin-bottom: -20px;
        font-size: 39px;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /******************************************** contact *****************************************/
    /**********************************************************************************************/

    .contact__picture {
        left: -26vw;
        width: 250vw;
    }
}