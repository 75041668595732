/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /******************************************** footer ******************************************/
    /**********************************************************************************************/

    .footer__container-info__content {
        top: 73px;
    }

    .footer__container-info__li {
        margin-left: 34%;
    }
}