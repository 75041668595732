/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /***************************************** pages-headers **************************************/
    /**********************************************************************************************/
    
    .paragraph {
        margin-left: 18vw;
        margin-right: 18vw;
    }

    .container-icons {
        margin-top: 8vw;
    }
}