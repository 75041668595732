/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /******************************************* partners ****************************************/
    /**********************************************************************************************/

    .partners__card {
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
    }
}