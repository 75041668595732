/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /******************************************** contact *****************************************/
    /**********************************************************************************************/

    .contact__picture {
        left: 0;
        width: 182vw;
    }
}