/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /**************************************** business-form ***************************************/
    /**********************************************************************************************/

	.labels {
        top: 1280px;
    }

	.business-form {
		top: 1360px;
	}
	
	.contenair-project-other {
		top: 1282px;
	}
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ***************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /**************************************** business-form ***************************************/
    /**********************************************************************************************/

    .labels {
        top: 1230px;
    }

	.business-form {
		top: 1310px;
	}
	
	.contenair-project-other {
		top: 1232px;
	}
}