@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /***************************************** contact-form ***************************************/
    /**********************************************************************************************/

    .form-contact {
        top: 850px;
    }
}

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /***************************************** contact-form ***************************************/
    /**********************************************************************************************/

    .form-contact {
        top: 950px;
    }
}