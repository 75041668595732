/**********************************************************************************************/
/********************************************* error ******************************************/
/**********************************************************************************************/

.error {
    position: absolute;
    text-align: center;
    width: 100%;
    height: auto;
    padding-bottom: 180vw;
    background: linear-gradient(rgb(17, 29, 39), rgb(108, 121, 129));
    color: rgb(231, 244, 253);
    top: 0;
    z-index: 2000;
}

.error__statut {
    position: relative;
    top: 5vw;
    font-family: Open, sans-serif;
    font-size: 20vw;
    font-weight: bold;
    margin-bottom: 7vw;
}

.error__failled {
    position: relative;
    width: 50vw;
    height: auto;
}

.error__subheader {
    position: relative;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 2vw;
    font-family: Open, sans-serif;
    font-size: 5vw;
    font-weight: bold;
    text-transform: uppercase;
    color: rgb(231, 244, 253);
}

.error__link {
    margin-left: 5vw;
    margin-right: 5vw;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Open, sans-serif;
    font-size: 5vw;
    font-weight: 500;
    letter-spacing: .2vw;
    color: rgb(162, 215, 253);
    transition: color .3s;
}

.error__link:hover {
    color: rgb(18, 255, 255);
    transition: color .3s;
}

.error__link:active {
    color: rgb(162, 215, 253);
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /********************************************* error ******************************************/
    /**********************************************************************************************/

    .error__statut {
        top: -20px;
        font-size: 80px;
        margin-bottom: -17px;
    }
    
    .error__failled {
        width: 200px;
    }
    
    .error__subheader {
        margin-bottom: 10px;
        font-size: 18px;
    }
    
    .error__link {
        font-size: 18px;
        letter-spacing: 1px;
    }
}