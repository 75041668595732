/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/
@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /********************************************* futur ******************************************/
    /**********************************************************************************************/
    
    .futur__container {
        height: 2050px;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* futur ******************************************/
    /**********************************************************************************************/

    .futur__container {
        height: 1900px;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /********************************************* futur ******************************************/
    /**********************************************************************************************/

    .futur__container {
        height: 1750px;
    }
}