/**********************************************************************************************/
/******************************************** footer ******************************************/
/**********************************************************************************************/

.footer {
	position: relative;
    width: 100%;
	height: auto;
    text-align: center;
	background-color: black;
}

.footer__fellow-me {
    margin-top: 0;
    margin-bottom: 20vw;
    padding-top: 10vw;
    background-color: black;
}

.footer__link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    top: -10vw;
    left: 50%;
    transform: translate(-50%, 0);
    width: 75vw;
    height: 20vw;
    text-align: center;
    align-items: center;
}

.footer__link__icon {
	position: relative;
    color: white;
    font-size: 9vw;
    opacity: .5;
    cursor: pointer;
    transition: all .2s;
}

.footer__link__icon:hover {
	color: rgb(112, 190, 247);
    font-size: 15vw;
    opacity: 1;
    transition: all .2s;
}

.footer__logo-vowd {
    position: relative;
    margin-bottom: 2vw;
	top: 4.5vw;
    width: 18vw;
	height: auto;
}

.footer__logo > span {
    color: rgb(138, 138, 138);
}

.footer__website {
    position: relative;
    margin-bottom: 15vw;
    font-family: 'Open Sans', sans-serif;
	font-size: 4vw;
    font-weight: 200;
    line-height: 5.8vw;
    color: rgb(192, 192, 192);
    text-decoration: none;
}

.footer__website > span {
    font-size: 4vw;
    font-family: 'Michroma', sans-serif;
    font-weight: bold;
    letter-spacing: .5vw;
    color: rgb(231, 244, 253);
}

.footer__container-info {
    position: relative;
    margin-bottom: -10vw;
    width: 100%;
    max-height: 76vw;
    font-family: 'Open', sans-serif;
    background-color: rgba(126, 126, 126, 0.4);
    overflow: hidden;
}

.picture-people {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    opacity: .5;
}

.picture-people2 {
    display: none;
}

.footer__container-info__content {
    position: relative;
    top: -63vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.footer__container-info__li {
    width: 75vw;
    height: 12vw;
    text-align: left;
    margin-left: 15vw;
    font-weight: 100;
    letter-spacing: .1vw;
    color: white;
}

.footer__container-info__li__icon-info {
    font-size: 6.5vw;
}

.footer__container-info__li__text {
    font-size: 4.2vw;
    font-weight: 300;
    margin-top: -6vw;
    margin-left: 11vw;
    left: 11vw;
}

.footer__container-info__li__text__link {
    text-decoration: none;
    color: rgb(154, 239, 250);
    transition: color .4s;
    cursor: pointer;
}

.footer__container-info__li__text__link:hover {
    text-decoration: none;
    color: rgb(0, 225, 255);
    transition: color .4s;
}

.footer__copyright {
    position: relative;
    width: auto;
    height: 10vw;
    font-family: 'Open', sans-serif;
    font-size: 4vw;
    line-height: 10vw;
    z-index: 1000;
    background-color: rgb(32, 32, 32);
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /******************************************** footer ******************************************/
    /**********************************************************************************************/
    
    .footer__fellow-me {
        margin-top: 0;
        margin-bottom: 142px;
        padding-top: 72px;
    }
    
    .footer__link {
        top: -72px;
        width: 340px;
        height: 80px;
    }
    
    .footer__link__icon {
        font-size: 40px;
    }
    
    .footer__link__icon:hover {
        font-size: 60px;
    }
    
    .footer__logo-vowd {
        margin-bottom: 5px;
        top: 15px;
        width: 90px;
    }
    
    .footer__website {
        margin-bottom: 100px;
        font-size: 20px;
        line-height: 30px;
    }
    
    .footer__website > span {
        font-size: 18px;
		letter-spacing: 2px;
    }
    
    .footer__container-info {
        margin-bottom: -5vw;
        max-height: 76vw;
    }

    .picture-people2 {
        position: absolute;
        display: block;
        width: auto;
        height: 400px;
        right: 0;
        opacity: .7;
    }
    
    .picture-people {
        display: none;
    }
    
    .footer__container-info__content {
        top: 83px;
        height: 400px;
    }
    
    .footer__container-info__li {
        margin-left: 22.5%;
        width: 360px;
        height: 60px;
        letter-spacing: .6px;
    }
    
    .footer__container-info__li__icon-info {
        font-size: 30px;
    }
    
    .footer__container-info__li__text {
        font-size: 17px;
        margin-top: -34px;
        margin-left: 50px;
        left: 50px;
    }
    
    .footer__copyright {
        height: 40px;
        font-size: 16px;
        line-height: 40px;
    }
}
