/**********************************************************************************************/
/********************************************* header *****************************************/
/**********************************************************************************************/

.navbar {
	position: relative;
    width: auto;
	background: linear-gradient(to top, rgb(97, 107, 114), 20%, transparent);
	transition: background-color 2s;
	z-index: 1;
}

.navbar-closed {
	height: 30vw;
	background-color: rgb(32, 53, 65);
	transition: height .3s, background-color .8s;
}

.navbar-open {
	height: 125vw;
	background-color:  rgba(50, 62, 70, 0.25);
	transition: height .4s, background-color 1.5s;
}

.navbar__logo-vowd {
	position: relative;
	top: 4.5vw;
	margin-left: 8vw;
	width: 9vw;
	height: auto;
	z-index: -1;
}

.navbar__title-vowd {
	position: relative;
	margin-left: -12vw;
	top: 9.5vw;
    font-size: 3vw;
    font-family: 'Michroma', sans-serif;
    font-weight: bold;
    letter-spacing: .5vw;
	color: rgb(231, 244, 253);
	z-index: 2;
}

.navbar__button-x {
	position: absolute;
    float: right;
    top: 16vw;
	right: 8.7vw;
	font-size: 6vw;
	color: white;
	transition: color .4s;
	cursor: pointer;
	z-index: 500;
}

.navbar__button-x:hover {
	color: rgb(204, 1, 1);
	transition: color .4s;
}

.navbar__button-bars {
	position: absolute;
    float: right;
    top: 17vw;
	right: 8.4vw;
	font-size: 5vw;
	color: white;
	cursor: pointer;
	z-index: 500;
}

.closed {
	display: none;
}

.open {
	display: block;
}

.navbar__cgu {
	position: relative;
	width: 0;
	top: 8vw;
	left: 5vw;
	font-size: 3vw;
	cursor: pointer;
	color: white;
	transition: color .4s;
}

.navbar__cgu:hover {
	color: rgb(0, 225, 255);
	transition: color .4s;
}

menu {
	position: relative;
	display: flex;
	flex-direction: column;
	top: 15vw;
	width: 87vw;
	overflow: hidden;
}

.navbar__menu {
	margin-left: -4.5vw;
}

.navbar__menu-open {
	height: 80vw;
	transition-delay: .2s;
	transition-duration: .25s;
}

.navbar__menu-closed {
	height: 0;
	transition-duration: .1s;
}

.nav__menu__link, a:link, a:visited, a:hover {
	text-decoration: none !important;
}

.nav__menu__link {
	position: relative;
	line-height: 10vw;
	border-bottom: 1px solid rgba(107, 177, 224, .4);
    padding-bottom: .5vw;
	cursor: pointer;
	letter-spacing: .3vw;
	font-size: 5.5vw;
	font-weight: 300;
	color: white;
	transition: all .3s;
}

.nav__menu__link:hover {
	border-bottom: 1px solid white;
	text-decoration: none;
	padding-left: 5vw;
	font-size: 5.9vw;
	font-weight: 400;
	color: white;
	transition: all .2s;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /********************************************* header *****************************************/
    /**********************************************************************************************/
	.navbar-closed {
		height: 90px;
	}
	
	.navbar-open {
		height: 600px;
	}

	.navbar__logo-vowd {
		top: 32px;
		margin-left: 45px;
		width: 40px;
	}

	.navbar__title-vowd {
		margin-left: 0;
		top: 17px;
		font-size: 18px;
		letter-spacing: 2px;
	}

	.navbar__button-x {
		top: 40px;
		right: 40px;
		font-size: 25px;
	}
	
	.navbar__button-bars {
		top: 42px;
		right: 40px;
		font-size: 20px;
	}

	.navbar__cgu {
		display: inline-block;
		top: 17px;
		left: 20px;
		font-size: 11px;
	}
	
	menu {
		top: 50px;
		width: 80vw;
	}
	
	.navbar__menu {
		margin-left: 5px;
	}
	
	.navbar__menu-open {
		height: 500px;
	}
	
	.nav__menu__link {
		line-height: 50px;
		padding-bottom: 5px;
		letter-spacing: 2px;
		font-size: 22px;
	}
	
	.nav__menu__link:hover {
		padding-left: 60px;
		font-size: 25px;
	}
    
}