/**********************************************************************************************/
/******************************************* videovowd ****************************************/
/**********************************************************************************************/

.video-vowd {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: auto;
    padding-left: 7vw;
    padding-right: 7vw;
    padding-bottom: 180vw;
    background: linear-gradient(rgb(17, 29, 39), rgb(108, 121, 129));
    color: rgb(231, 244, 253);
    top: 0;
    z-index: 2000;
}

.video-vowd__wrapper {
    width: auto;
    height: 48vw;
    margin-top: 30px;
    border-radius: 10px;
    overflow: hidden;
}

.video-vowd__wrapper__vdo {
    top: 0;
    left: 0;
}

.video-vowd__logo-vowd {
    display: none;
    position: relative;
	margin-top: 50vw;
    margin-left: 8vw;
    width: 14vw;
    height: auto;
}

.video-vowd__title-vowd {
	position: relative;
    display: none;
	margin-top: -11vw;
    margin-left: 8vw;
    margin-bottom: 15px;
    font-size: 6vw;
    font-family: 'Michroma', sans-serif;
    font-weight: bold;
    letter-spacing: .5vw;
	color: rgb(231, 244, 253);
	z-index: 2;
}

.video-vowd__text {
    display: none;
    margin-left: 7.7vw;
    font-size: 2.2vw;
}