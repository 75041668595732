/**********************************************************************************************/
/****************************************** pub-warning ***************************************/
/**********************************************************************************************/

.pub-warning {
    display: none;
    position: fixed;
    text-align: center;
    top: 380px;
    left: 50%;
    transform: translate(-50%, 0);
    padding-top: 13px;
    width: auto;
    height: auto;
    z-index: 5000;
}

.pub-warning-boule {
    position: relative;
    width: 200px;
    height: auto;
    transform: rotate(-20deg);
    z-index: 5000;
}

.pub-warning-subtitle{
    position: absolute;
    display: inline-block;
    left: 37px;
    top: 110px;
    font-size: 60px;
    line-height: 0;
    transform: rotate(-20deg);
    z-index: 5000;
}