/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /******************************************* letsTalk *****************************************/
    /**********************************************************************************************/

    .letsTalk__discount {
        top: 1100px;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /******************************************* letsTalk *****************************************/
    /**********************************************************************************************/

    .letsTalk {
        margin-bottom: -1300px;
    }

    .letsTalk__vdo {
        top: -1300px;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1299px ***************************************/

@media screen and (min-width: 1300px) {

    /**********************************************************************************************/
    /******************************************* letsTalk *****************************************/
    /**********************************************************************************************/

    .letsTalk {
        margin-bottom: -1550px;
    }

    .letsTalk__vdo {
        top: -1550px;
    }
}