/**********************************************************************************************/
/******************************************* costumers ****************************************/
/**********************************************************************************************/

.costumers {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding-bottom: 2vw;
    padding-left: 7vw;
    padding-right: 7vw;
    background: linear-gradient(rgb(17, 29, 39), rgb(108, 121, 129));
    color: rgb(231, 244, 253);
    top: 0;
    z-index: 2000;
}

.costumers__title {
    text-align: center;
    margin-bottom: 50px;
}

.costumers__card {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    width: 100%;
    height: auto;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: white;
    transition: background-color .4s;
}

.costumers__card:hover {
    background-color: rgb(215, 232, 247);
    transition: background-color .4s;
}

.costumers__card__info {
    color: black;
    font-family: Open, sans-serif;
}

.icons-delete{
    display:flex;
    gap: 14px;
}

.icon-delete{
    color: red !important;
}

.icon-back:hover{
    color: rgb(41, 195, 216) !important;
}

.confirm-visible {
    display: block;
}

.confirm-hidden {
    display: none;
}

.no-data{
    display:flex;
    justify-content:center;
    font-size:30px;
}

.link-card{
    cursor: pointer;
    width: 85%;
}

@media screen and (max-width: 451px) {
        .icons-delete {
        flex-direction: column;
    }
}