/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /******************************************* services *****************************************/
    /**********************************************************************************************/

    .services__container__picture2 {
        height: 100vw;
        left: 0;
    }
}