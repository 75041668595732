.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3100;
}

.modal {
    min-width:40%;
    background-color: rgb(93, 92, 92);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.cancel-button {
    background-color: tomato;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.primary {
    background-color: #007bff;
    /* Couleur primaire, vous pouvez la personnaliser */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button:hover {
    background-color: #c51a1a;
    /* Couleur rouge au survol */
}

.primary:hover {
    background-color: #0056b3;
    /* Couleur primaire au survol */
}

.modal-buttons {
    margin-top: 20px;

}

.modal-buttons button {
    margin: 0 10px;
}

@media screen and (max-width: 600px) {
    .modal {
    width: 90%;
    
}
}