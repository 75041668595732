/**********************************************************************************************/
/********************************************* about ******************************************/
/**********************************************************************************************/

.about__icon {
    margin-bottom: -5.6vw;
    font-size: 10.5vw;
    color: rgb(112, 190, 247);
} 

@keyframes bounce { 
    from {transform: translateY(0);} to {transform: translateY(-3vw);} 
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /********************************************* about ******************************************/
    /**********************************************************************************************/

    .about__icon {
        margin-bottom: -22px;
        font-size: 40px;
    } 
}