/**********************************************************************************************/
/****************************************** background ****************************************/
/**********************************************************************************************/

.background {
	position: absolute;
    width: auto;
    height: auto;
    overflow: hidden;
    z-index: -1;
}

.keyboard {
    position: fixed;
    margin: 0 auto;
    top: 0;
    width: 200vw;
    height: auto;
    filter: brightness(120%);
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /****************************************** background ****************************************/
    /**********************************************************************************************/

    .keyboard {
        width: 190vw;
    }
}