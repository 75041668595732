/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* vowd *******************************************/
    /**********************************************************************************************/

    .vowd__filtre {
        font-size: 50px;
        line-height: 55px;
    }  
    
    .vowd__filtre__text {
        width: 500px;
        margin-top: 35vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /********************************************* vowd *******************************************/
    /**********************************************************************************************/

    .vowd__filtre {
        font-size: 60px;
        line-height: 70px;
    } 

    .vowd__filtre__text {
        width: 600px;
    }
}