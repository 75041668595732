/**********************************************************************************************/
/********************************************* vowd *******************************************/
/**********************************************************************************************/

.vowd {
    position: relative;
    width: 100%;
    height: auto;
}

.vowd__img {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
}

.vowd__filtre {
    width: 100%;
    height: 65.8vw;
    padding: 5vw;
    align-items: center;
    font-size: 7vw;
    font-weight: 500;
    line-height: 8vw;
    font-family: 'Open', sans-serif;
    background-color: rgba(19, 34, 46, 0.7);
}

.vowd__filtre__text {
    display: inline-block;
    margin-top: 28vw;
    width: 100%;
    height: auto;
    color: rgb(168, 255, 246);
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /********************************************* vowd *******************************************/
    /**********************************************************************************************/

    .vowd__filtre {
        font-size: 35px;
        line-height: 45px;
    } 
    
    .vowd__filtre__text {
        margin-top: 25vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /********************************************* vowd *******************************************/
    /**********************************************************************************************/

    .vowd__filtre {
        font-size: 35px;
        line-height: 45px;
    }  
    
    .vowd__filtre__text {
        width: 300px;
        margin-top: 30vw;
    }
}