/**********************************************************************************************/
/******************************************** alert *******************************************/
/**********************************************************************************************/

.alert-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 35vw;
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 700px;
    width: 90vw;
    height: auto;
    border-radius: 5vw;
    background: linear-gradient(rgb(17, 29, 39), rgb(108, 121, 129));
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 1000;
}

.alert-container__closed {
    position: absolute;
    right: 5vw;
    margin-top: 5vw;
    font-size: 8vw;
    color: rgb(0, 225, 255);
    cursor: pointer;
    transition: color .4s;
}

.alert-container__closed:hover {
    color: rgb(204, 1, 1);
    transition: color .4s;
}

.alert-container>header>h1 {
    margin-top: 12vw;
    text-align: center;
    letter-spacing: .2vw;
    color: white;
    font-size: 6vw;
}

.alert-container>p {
    position: relative;
    margin-bottom: 8vw;
    text-align: center;
    font-size: 4.5vw;
    line-height: 7vw;
    letter-spacing: .2vw;
    color: white;
}

.alert-container__signature {
    text-align: right;
}

.signature {
    float: right;
    width: 40vw;
    height: auto;
    filter: brightness(200%);
}

.alert-container>p>strong>span {
    font-size: 5vw;
}

.alert-container__aside {
    width: 100%;
    height: auto;
    border-radius: 70px;
    margin-bottom: 30px;
    padding: 10px;
    background: radial-gradient(circle, rgb(103, 216, 236), rgb(86, 201, 236), rgb(62, 168, 182));
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: rotate(-5deg);
    -webkit-transform: rotate(-5deg);
}

.alert-container__aside__header {
    text-align: center;
}

.alert-container__aside__header>h1 {
    font-size: 5vw;
    letter-spacing: .1vw;
}

#alert-container__aside__info {
    font-family: 'Open', sans-serif;
    letter-spacing: .1vw;
    font-size: 4.5vw;
    margin-left: 5px;
    margin-right: 5px;
}

.alert-container__aside__info__link {
    font-weight: bold;
    cursor: pointer;
    color: rgb(24, 41, 54);
    transition: color .4s;
}

.alert-container__aside__info__link:hover,
.alert-container__aside__info__link:active {
    color: rgb(22, 70, 172);
    text-decoration: underline;
    transition: color .4s;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /********************************************* alert ******************************************/
    /**********************************************************************************************/
	.alert-container {
        top: 150px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 50px;
        border-radius: 30px;
    }
    
    .alert-container__closed {
        right: 25px;
        margin-top: 20px;
        font-size: 30px;
    }

    .alert-container>header>h1 {
        margin-top: 40px;
        letter-spacing: 1px;
        font-size: 22px;
    }
    
    .alert-container>p {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 1px;
    }

    .alert-container>p>strong>span {
        font-size: 18px;
    }

    .signature {
        width: 30vw;
    }
    
    .alert-container__aside__header>h1 {
        font-size: 30px;
    }

    .alert-container__aside__header>h1 {
        letter-spacing: 1px;
    }
    
    #alert-container__aside__info {
        letter-spacing: 1px;
        font-size: 16px;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /********************************************* alert ******************************************/
    /**********************************************************************************************/

    .alert-container__aside {
        width: 500px;
        float: right;
        margin-right: 5vw;
        transform: rotate(-3deg);
        -webkit-transform: rotate(-3deg);
    }

    .signature {
        width: 200px;
    }

    #alert-container__aside__info {
        font-size: 20px;
    }
}