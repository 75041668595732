/**********************************************************************************************/
/****************************************** scrollToTop ***************************************/
/**********************************************************************************************/

.top-to-btm {
    position: relative;
    z-index: 2000;
}

.icon-position {
    position: fixed;
    top: 90vw;
    right: 25px;
    z-index: 20;
}

.icon-style {
    background-color: rgba(02, 143, 235, 0.4);
    border-radius: 6px;
    height: 50px;
    width: 50px;
    color: rgb(239, 234, 237);
    cursor: pointer;
    transition: all .7s ease-in-out;
}

.icon-style:hover {
    animation: none;
    background: rgb(02, 143, 235);
    color: rgb(0, 31, 44);
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /****************************************** scrollToTop ***************************************/
    /**********************************************************************************************/

    .icon-position {
      top: 150px;
      z-index: 3000;
    }
}