/**********************************************************************************************/
/**************************************** business-form ***************************************/
/**********************************************************************************************/

.labels {
    position: absolute;
    top: 379vw;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: row;
    width: 84.5vw;
    font-family: Open, sans-serif;
    font-size: 6vw;
    font-weight: 700;
    letter-spacing: .1vw;
    color: white;
}

.labels__projet {
    margin-left: 5.6vw;
}

.labels__budget {
    margin-left: 8.5vw;
}

.labels__delai {
    margin-left: 10vw;
}

.business-form {
	position: absolute;
    display: flex;
	top: 65%;
	width: 100%;
	visibility: visible;
}

.business-form__container {
    position: relative;
	left: 50%;
	transform: translate(-50%, 0);
    top: 47vw;
	display: flex;
	flex-direction: row;
	width: 84.5vw;
	height: auto;
	margin-left: -.8vw;
}

.business-form__container__work, 
.business-form__container__budget, 
.business-form__container__dead-line {
    position: relative;
	display: flex;
	flex-direction: column;
	width: 84.5vw;
	margin-left: -.8vw;
	height: auto;
}

label span {
	font-size: 7.5vw;
}

.work-option--padding1 {
	padding-top: 5.5vw;
}

.work-option--padding2 {
	padding-top: 2.7vw;
}

.option + label {
	position: relative;
	text-align: center;
  	width: 25vw;
	min-height: 16vw;
	height: auto;
  	border-radius: 1.2vw;
 	color: white;
    font-family: Open, sans-serif;
 	font-size: 4.5vw;
    font-weight: 600;
    letter-spacing: .1vw;
    margin-left: 3vw;
 	cursor: pointer;
    background-color: rgba(221, 221, 221, 0.6);
 	transition: background-color .5s;
}

.option {
	margin-bottom: 3vw;
}

.option:hover + label {
    background-color: rgba(2, 143, 235, 0.6);
    color: white;
    transition: background-color .5s;
}
.option:checked + label {
    background-color: rgba(2, 143, 235, 0.6);
    color: white;
}

.project-submit {
	position: absolute;
	text-align: center;
    padding: 5vw;
	left: 50%;
	transform: translate(-50%, 0);
	font-size: 6vw;
    font-weight: 500;
    letter-spacing: .1vw;
    border-radius: 1.2vw;
	bottom: -75vw;
}

.contenair-project-other {
	position: absolute;
	top: 375vw;
	left: 50%;
	transform: translate(-50%, 0);
	width: 80vw;
	height: auto;
	border-radius: 2.5vw;
	background-color: rgba(0, 31, 44, 0.6);
	overflow: hidden;
}

.contenair-project-other__title {
	position: relative;
	text-align: center;
	top: 0;
	margin-top: 0;
	width: 100%;
	padding: 5vw;
	font-size: 4.8vw;
	font-weight: 500;
	letter-spacing: .2vw;
	color: white;
	background-color: rgb(4, 94, 155);
}

.description {
	display: none;
}

.other-closed {
    position: absolute;
    top: 2vw;
    margin-left: 61vw;
    font-size: 4vw;
    color: white;
    transition: .2s;
    cursor: pointer;
    z-index: 500;
}

.other-closed:hover {
    font-size: 5.5vw;
    color: rgb(255, 0, 0);
    transition: .2s;
}

.other-closed:active {
    color: white;
}

.contenair-project-other__input {
	position: relative;
	resize: none;
	left: 50%;
	transform: translate(-50%, 0);
	margin-bottom: 5vw;
	width: 70vw;
	height: 70vw;
	font-family: Open, sans-serif;
	font-size: 4.5vw;
	color: white;
	background-color: transparent;
}

.contenair-project-other__button {
	position: relative;
	text-align: center;
	border: 0;
	bottom: 0;
	margin-bottom: 0;
	width: 100%;
	height: 15vw;
	background-color: rgb(4, 94, 155);
}

.other-button {
	cursor: pointer;
	font-size: 8vw;
    color: white;
	transition: font-size .1s;
	z-index: 500;
}

.other-button:hover {
	font-size: 9.5vw;
	transition: font-size .1s;
}

.other-button:active{
	color: rgb(114, 156, 184);
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /**************************************** business-form ***************************************/
    /**********************************************************************************************/
	.labels {
        top: 1600px;
        margin-left: 0;
        width: 300px;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .labels__projet {
        left: 0;
        margin-left: -25px;
    }
    
    .labels__budget {
        left: 0;
        margin-left: 37px;
    }
    
    .labels__delai {
        left: 0;
        margin-left: 35px;
    }
	
	.business-form {
		top: 1380px;
	}
	
	.business-form__container {
		top: 280px;
		width: 382px;
		margin-left: -3.6px;
	}
	
	.business-form__container__work, 
	.business-form__container__budget, 
	.business-form__container__dead-line {
		width: 382px;
		margin-left: -3.6px;
	}
	
	label span {
		font-size: 31.9px;
	}
	
	.work-option--padding1 {
		padding-top: 24px;
	}
	
	.work-option--padding2 {
		padding-top: 12px;
	}
	
	.option + label {
		width: 113px;
		min-height: 68px;
		border-radius: 5.5px;
		font-size: 21px;
		letter-spacing: .45px;
		margin-left: 14px;
	}

	.option {
		margin-bottom: 14px;
	}
	
	.project-submit {
		padding: 23px;
		font-size: 27px;
		letter-spacing: .45px;
		border-radius: 5.5px;
		bottom: -400px;
	}
	
	.contenair-project-other {
		position: absolute;
		top: 1602px;
		width: 360px;
		border-radius: 11.5px;
	}
	
	.contenair-project-other__title {
		padding: 22px;
		font-size: 22px;
		letter-spacing: .9px;
	}
	
	.other-closed {
		top: 10px;
		margin-left: 277px;
		font-size: 18px;
	}
	
	.other-closed:hover {
		top: 8px;
		margin-left: 274px;
		font-size: 24px;
	}
	
	.contenair-project-other__input {
		margin-bottom: 22.6px;
		width: 318px;
		height: 318px;
		font-size: 20.5px;
	}
	
	.contenair-project-other__button {
		height: 70px;
	}
	
	.other-button {
		font-size: 36px;
	}
	
	.other-button:hover {
		font-size: 42px;
	}
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /**************************************** business-form ***************************************/
    /**********************************************************************************************/
	.labels {
        top: 1350px;
    }

	.business-form {
		top: 1430px;
	}

	.project-submit {
		bottom: -100px;
	}
	
	.business-form__container {
		top: -20px;
	}
	
	.contenair-project-other {
		top: 1352px;
	}
}