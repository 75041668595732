/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* banner *****************************************/
    /**********************************************************************************************/

    .banner {
        padding-left: 300px;
    }

    .banner__pub {
        height: 25px;
    }

    .banner__pub>p {
        font-size: 14px;
    }
      
    .banner__title {
        top: 10px;
        margin-left: -260px;
        margin-bottom: 70px;
        font-size: 50px;
        line-height: 100px;
        letter-spacing: 6px;
    }
    
    .banner__title > span {
        font-size: 150px;
    }

    .banner__title-o {
        margin-right: 5px;
    }

    .banner__button {
        top: -46vw;
        padding-left: 9px;
        width: 220px;
        height: 62px;
        border-radius: 30px;
        z-index: 1000;
    }

    .banner__info {
        display: block;
    }

    .banner__subtitle {
        /*top: -380px;*/
        top: 200px;
        display: inline-block;
        text-align: center;
        width: 100vw;
        margin-left: -300px;
        font-size: 50px;
        letter-spacing: 2px;
    }
}