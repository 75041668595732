/**********************************************************************************************/
/******************************************** partners ****************************************/
/**********************************************************************************************/

.partners {
    text-align: center;
    width: 100%;
    height: auto;
    padding-top: 5vw;
    padding-bottom: 15vw;
    background: linear-gradient(rgb(17, 29, 39), rgb(108, 121, 129));
    color: rgb(231, 244, 253);
    top: 0;
    z-index: 1000;
}

.partners__title {
    margin-bottom: 60px;
}

.partners__card {
    text-align: left;
    width: 80vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 20px;
    background-color: white;
    color: rgb(33, 92, 141);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    transition: background-color .4s;
}

.partners__card:hover {
    background-color: rgb(76, 90, 102);
    color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transition: background-color .4s, color .4s;
}

.partners__card:active {
    background-color: rgb(255, 255, 255);
    color: rgb(33, 92, 141);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.partners__card__wrapper {
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    overflow: hidden;
}

.partners__card:hover .partners__card__wrapper {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.partners__card__wrapper__pic {
    width: 20vw;
    height: 20vw;
}

.partners__card__name {
    margin-top: -12vw;
    margin-left: 23vw;
    font-size: 4vw;
    font-weight: 500;
}

.partners__card__statut {
    margin: 0;
    font-size: 3.5vw;
}

.partners__card__portfolio {
    text-align: center;
    display: block;
    width: 70vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    word-wrap: break-word;
    font-size: 4vw;
    font-weight: 600;
}

.partners__card:hover .partners__card__portfolio {
    color: rgb(113, 238, 255) !important;
    transition: color 1s;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /******************************************* partners ****************************************/
    /**********************************************************************************************/

    .partners {
        padding-top: 50px;
        padding-bottom: 80px;
    }
    
    .partners__card__wrapper,
    .partners__card__wrapper__pic {
        width: 85px;
        height: 85px;
    }
    
    .partners__card__name {
        margin-top: -45px;
        margin-left: 100px;
        font-size: 20px;
    }
    
    .partners__card__statut {
        font-size: 16px;
    }
    
    .partners__card__portfolio {
        font-size: 16px;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /******************************************* partners ****************************************/
    /**********************************************************************************************/

    .partners {
        padding-bottom: 120px;
    }
}